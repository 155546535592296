import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './common/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import {ArkreenLogoLongIcon} from "@/icons/ArkreenIcon";
//
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const isComing = true
root.render(
    <BrowserRouter>
        {isComing ? <div style={{
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            alignItems: 'center',
            flexDirection: 'column'
        }}>

            <div style={{textAlign: 'center'}}>
                <ArkreenLogoLongIcon/>
            </div>
            <div style={{color: '#00913A', fontSize: '32px', fontWeight: 700}}>
                <a style={{color: '#00913A',textDecoration:'unset'}} href='https://docs.arkreen.com/quick-start/mainnet-transition' target='_blank' rel="noreferrer">Mainnet
                    coming on Feb.28</a>
            </div>
        </div> : <App/>}

    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
